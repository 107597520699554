























































































import { defineComponent, ref, Ref, computed, ComputedRef, watch } from '@vue/composition-api';
import { DataTableHeader } from 'vuetify';
import { History, useHistory } from '@/admin/history';
import { useUser } from '@/admin/user';
import { usePayment } from '@/admin/payment';
import FcCheckFilter from '@/components/FcCheckFilter.vue';
import FcDateFilter from '@/components/FcDateFilter.vue';
import { useNotification } from '@/composition/notification';
import core from '@/admin/core';
import { downloadCsv } from '@/admin/util';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';

export default defineComponent({
  name: 'Histories',
  components: {
    FcCheckFilter,
    FcDateFilter,
    FcRoleLoading,
    FcRoleDeny,
  },
  setup() {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('histories'));

    const { getHistories } = useHistory();
    const { userProperties } = useUser();
    const { getSubscriptionPlans } = usePayment();
    const notification = useNotification();

    const headers: ComputedRef<DataTableHeader[]> = computed(() => {
      return [
        { text: '決済日', value: 'createDate.dateTimeSeconds' },
        { text: 'プラン', value: 'subscriptionPlan.subscriptionPlanName' },
        { text: '決済金額', value: 'totalPrice' },
        { text: 'タイプ', value: 'updateType' },
        { text: 'ユーザーID', value: 'username' },
        { text: 'メールアドレス', value: 'user.email' },
        ...userProperties.value.map(({ key, title }) => ({ text: title, value: `user.properties.${key}` })),
        { text: '会員番号', value: 'user.userNumber' },
        { text: '登録日', value: 'user.createDate' },
        { text: '備考', value: 'user.note' },
      ];
    });

    const items: Ref<History[]> = ref([]);
    const isLoading = ref(true);
    const nextToken = ref('');
    const nextLoading = ref(false);
    const filterDates: Ref<(number | null)[]> = ref([null, null]);

    // もっと読み込む
    const loadNext = async () => {
      nextLoading.value = true;
      getHistories('subscriptionPlan', nextToken.value).then((result) => {
        items.value = [...items.value, ...result.histories];
        nextLoading.value = false;
        nextToken.value = result.nextToken || '';
      });
    };

    // プランで絞り込み
    const selectedPlanValues: Ref<string[]> = ref([]);
    const plans: Ref<Option[]> = ref([]);
    getSubscriptionPlans().then((result) => {
      plans.value = result.map((plan) => ({
        text: plan.subscriptionPlanName,
        value: plan.subscriptionPlanId,
      }));
    });

    // タイプで絞り込み
    const selectedTypeValues: Ref<string[]> = ref([]);
    const types = [
      { text: '新規', value: 'new' },
      { text: 'アップグレード', value: 'upgrade' },
      { text: 'ダウングレード', value: 'downgrade' },
      { text: '解約', value: 'cancel' },
      { text: '継続', value: 'continue' },
      { text: 'トライアル（新規）', value: 'new-trial' },
      { text: 'トライアル（アップグレード）', value: 'upgrade-trial' },
    ];

    // フリーワードで絞り込み
    const searchWord = ref('');

    // 表示するアイテム一覧
    const histories = computed(() => {
      return items.value
        .filter(
          (item) =>
            !selectedPlanValues.value.length ||
            (item.subscriptionPlan && selectedPlanValues.value.includes(item.subscriptionPlan.subscriptionPlanId))
        )
        .filter(
          (item) =>
            !selectedTypeValues.value.length || (item.updateType && selectedTypeValues.value.includes(item.updateType))
        )
        .filter((item) => !searchWord.value || JSON.stringify(item).includes(searchWord.value));
    });

    watch(
      () => filterDates.value,
      () => {
        isLoading.value = true;
        items.value = [];
        getHistories('subscriptionPlan', nextToken.value, undefined, filterDates.value[0], filterDates.value[1])
          .then((result) => {
            items.value = result.histories;
            nextLoading.value = false;
            isLoading.value = false;
            nextToken.value = result.nextToken || '';
          })
          .catch((error) => {
            notification.error(error);
            isLoading.value = false;
          });
      }
    );

    // テーブル高さ
    const tableHeight = window.innerHeight - 64 - 40;

    // ダウンロードボタン
    const download = () => {
      const csvItems = histories.value.map((history) => {
        const _history = { ...history };
        _history.updateType = types.find((type) => type.value === history.updateType)?.text;
        return _history;
      });
      downloadCsv(headers.value, csvItems, 'subscription-history.csv');
    };
    return {
      pageTitle: 'プラン加入状況',
      myRoleSettings,
      headers,
      isLoading,
      nextToken,
      nextLoading,
      loadNext,
      download,
      selectedPlanValues,
      plans,
      selectedTypeValues,
      types,
      searchWord,
      histories,
      tableHeight,
      isPermitted: core.isPermitted,
      filterDates,
    };
  },
});
